import React from "react";

export default function Clients() {
    return(
        <main id="clients" className="story-section md:px-[40px] px-[20px] md:py-[80xp] py-[60px] w-full">
            <span className="bg-green text-white p-2 rounded">Who We Work With</span>
            <h1>Our Clients</h1>
            <div className="client w flex justify-evenly md:flex-row flex-col md:gap-[0] gap-[30px]">
                <div className="client-container md:w-2/6 w-full ">
                    <img src="/images/climate_logo.png" className="w-full" />
                    <div className="client-description bg-green text-white p-[17px] md:p2 rounded opacity-80">
                        <p className=" md:text-[30px] text-[20px]">Since 2017</p>
                    </div>
                </div>
                <div className="client-container md:w-2/6 w-full ">
                    <img src="/images/BWB_logo.png" className="w-full" />
                    <div className="client-description bg-black text-white p-[17px] md:p2 rounded opacity-80">
                    <p className=" md:text-[30px] text-[20px]">Since 2021</p>
                    </div>
                </div>

            </div>
        </main>
    )
}