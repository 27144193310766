import React from "react";
import './Qualifications.css'
import { FaCheck } from "react-icons/fa";


export default function Qualifications() {
    return(
        <main id="qualifications" className="qual-section md:px-[40px] px-[20px] md:py-[80px] py-[60px] w-full flex gap-5 flex-col items-center justify-center">
            <img src="/images/breAcademyTransparent.png" className="self-center w-2/5"/>
            <div className="background-image-div p-8 w-6/7 md:w-3/5 outline-dashed rounded">
                <ul className="flex flex-col gap-3">
                    <li className="flex items-center flex-nowrap">
                        <FaCheck className="check text-[50px] md:text-[40px] text-green text-bold"/>
                        <h2 className="ml-2 text-[20px] md:text-[40px]">BREEAM AP Qualified</h2>
                    </li>
                    <li className="flex items-center">
                        <FaCheck className="check text-[50px] md:text-[40px] text-green text-bold"/>
                        <h2 className="ml-2 text-[20px] md:text-[40px]">Qualified Staff</h2>
                    </li>
                    <li className="flex items-center">
                        <FaCheck className="check text-[50px] md:text-[40px] text-green text-bold"/>
                        <h2 className="ml-2 text-[20px] md:text-[40px]">Aware</h2>
                    </li>
                    <li className="flex items-center">
                        <FaCheck className="check text-[50px] md:text-[40px] text-green text-bold"/>
                        <h2 className="ml-2 text-[20px] md:text-[40px]">innovative Thinking</h2>
                    </li>
                </ul>
            </div>
        </main>
    )
}