import React from 'react';
import './Landing.css'

export default function Landing({onNavClick}) {

    const handleClick = () => {
        onNavClick('clients')
    }
    return(
        <main className="story-section px-[20px] md:py-[80xp] w-full px-[60px] pt-[100px] bg-gradient-to-r from-cyan-500 to-blue-500" id='landing'>
            <div class="" id="sectionA">
                <div class="flex flex-col md:flex-row items-center justify-center md:justify-between">
                    <div class="title-desc text-center md:text-left md:w-1/2 md:pr-8">
                        <h1 class="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">Creating a clean, liveable universe for all</h1>
                        <p class="text-base md:text-lg lg:text-xl mb-4">
                            "Nile Villa redefines eco-conscious living and business practices. As a sustainability consultancy, we create innovative solutions for a cleaner, healthier world in harmony with nature."
                        </p>
                        <div onClick={handleClick} class="button cursor-pointer bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 text-[25px] md:text-[30px]" id="learnMoreBtn" onclick="scrollDown()">Learn More</div>
                    </div>
                    <img src="/images/netzero.png" id="graph" class="md:w-1/2"/>
                </div>
            </div>
        </main>
    )
}