import React from "react";
// import './Projects.css'

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

import "swiper/css";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";

export default function Projects() {

    const playerContent = [
        {
          img: "/images/proj1_unitb.png",
          title: "Unity Connect Unit B ",
          content:
          "The development proposal includes the construction of a new 'Shell & Core' Industrial/Warehouse unit with ancillary offices, external yard, car park and service access."
          
        },
        {
            img: "/images/proj2_unit2.png",
            title: "Unit 2 - White Hart Lane Works ",
          content:
          "The project is for the replacement of an industrial unit within the White Hart Lane Park that was damaged following a fire in November 2019. The development is a ‘Shell and Core’ - Warehouse with associated offices, external delivery yards and car-parking."        },
        {
            img: "/images/proj3_heathlands.png",
            title: "Heathlands care home",
          content:
          "The construction of 66 bed Care Home for Dementia including an intermediate unit (20 beds) in Bracknell."                                                                                           },
      {
            img: "/images/proj4_bingley.png",
            title: "Bingley Street ",
          content:
"To construct new 407 bed living student accommodation located on Bingley Street -in two blocks."        }
    ];
    return(
        <main className="story-section md:px-[40px] px-[20px] md:py-[80xp] py-[60px] w-full" id="projects">
        {/* <section className="story-header flex items-center justify-center flex-col">
          <span className="mb-3 -badge">stories of success</span>
          <h2>our player stories</h2>
        </section> */}
        <span className="bg-green text-white p-2 rounded">Stories of Success </span>
        <h1>Our Projects</h1>

        {/* SWIPER SECTION */}
        <section className="swiper-section mt-4  select-none">
          <Swiper
            slidesPerView={2}
            spaceBetween={50}
            navigation={true}
            grabCursor={true}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              998: {
                slidesPerView: 2,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            <SwiperBtn />
            {playerContent.map((element, index) => {
              return (
                <SwiperSlide key={index} className="my-5">
                  <div className="story_card relative">
                    <img
                      src={element.img}
                      className="lg:max-w-[400px] max-w-[3000px] lg:h-[400px] object-cover"
                      alt=""
                    />
                    <div className="story_content md:max-w-[280px] max-w-[400px] absolute bottom-[-2rem] md:left-[14rem]  left-[2.5rem] bg-white py-3 px-6">
                      <h4 className="font-primary text-black font-medium mb-3 uppercase text-[30px]">
                        {element.title}
                      </h4>
                      <p className="text-justify text-gray leading-5 text-[16px] mb-4 ">
                        {element.content}
                      </p>
                      <button className="btn-primary bg-green rounded text-white p-2">
                        Learn more{" "}
                        {/* <MdOutlineKeyboardDoubleArrowRight className="ms-1 text-[20px]" /> */}
                      </button>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </section>
      </main>

    )


}


const SwiperBtn = () => {
    const swiper = useSwiper();
  
    return (
      <div className="swiper-btns w-full flex items-center justify-end gap-x-4 mt-10 pe-8">
        <button className="text-white bg-green transition duration-300 hover:bg-black hover:text-white text-[15px] w-[50px] h-[40px] flex items-center justify-center skew-x-[-10deg]" onClick={() => swiper.slideNext()}>
          <FaArrowLeftLong />
        </button>
        <button className="text-white bg-green transition duration-300 hover:bg-black hover:text-white text-[15px] w-[50px] h-[40px] flex items-center justify-center skew-x-[-10deg]"  onClick={() => swiper.slidePrev()}>
          <FaArrowRightLong />
        </button>
      </div>
    );
  };